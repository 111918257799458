import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import articleService from './articleService';

const initialState = {
  articles: [],
  articlesBanner: [],
  article: {},
  isError: false,
  isSuccess: false,
  updateBannerSuccess: false,
  isLoading: false,
  isAddPGSuccess: false,
  message: '',
};

// Get Articles
export const getArticles = createAsyncThunk(
  'articles/getAll',
  async (_, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticles();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getArticles2 = createAsyncThunk(
  'articles/getArticles2',
  async (_, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticles2();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Articles By TAG
export const getArticleByTag = createAsyncThunk(
  'articles/getArticleByTag',
  async (data, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticleByTag(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get photographer
export const getArticlesByPhotoGrapher = createAsyncThunk(
  'articles/getArticlesByPhotoGrapher',
  async (id, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticlesByAPhotoGrapher(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// REMOVE PHOTOGRAHPER FROM ARTICLE
export const removePhotographerArticle = createAsyncThunk(
  'articles/removePhotographerArticle',
  async (ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await articleService.removePhotographerArticle(ids, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addPhotoGrapherToArticle = createAsyncThunk(
  'articles/addPhotoGrapherToArticle',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await articleService.addPhotoGrapherToArticle(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Banner
export const getArticlesBanner = createAsyncThunk(
  'articles/getBanner',
  async (_, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticlesBanner();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get ARTICLE BY AUTHOR
export const getArticlesByAuthor = createAsyncThunk(
  'articles/getByAuthor',
  async (authorId, thunkAPI) => {
    try {
      //   const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticlesByAuthor(authorId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get article by id
export const getArticleById = createAsyncThunk(
  'articles/getArticleById',
  async (id, thunkAPI) => {
    try {
      //  const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticleById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get author by categiey
export const getArticlesByCategory = createAsyncThunk(
  'articles/getByCategory',
  async (catefory, thunkAPI) => {
    try {
      //    const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticlesByCategory(catefory);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get author by name
export const getArticlesByName = createAsyncThunk(
  'article/getByName',
  async (title, thunkAPI) => {
    try {
      //    const token = thunkAPI.getState().auth.user.token;
      return await articleService.getArticlesName(title);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new goal
export const createArticle = createAsyncThunk(
  'article/create',
  async (articleData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      console.log(articleData);
      return await articleService.createArticle(articleData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Edit Article
export const updateArticle = createAsyncThunk(
  'article/updateArticle',
  async (articleData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await articleService.editArticle(articleData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete user goal
export const deleteArticle = createAsyncThunk(
  'article/delete',
  async ({ payload, callback }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await articleService.deleteArticle({ payload, callback }, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// EDIT ARTICLE DATA
export const editArticleData = createAsyncThunk(
  'article/editArticleData',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await articleService.editArticleData(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete user goal
export const updateBannerArticle = createAsyncThunk(
  'article/updateBanner',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await articleService.updateBannerArticle(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Search Article
export const searchArticles = createAsyncThunk(
  'articles/searchArticles',
  async (text, thunkAPI) => {
    try {
      return await articleService.searchArticle(text);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticles2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticles2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticles2.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(removePhotographerArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removePhotographerArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.article = action.payload;
      })
      .addCase(removePhotographerArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(searchArticles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(searchArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticlesBanner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlesBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articlesBanner = action.payload;
      })
      .addCase(getArticlesBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticlesByAuthor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlesByAuthor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticlesByAuthor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticlesByPhotoGrapher.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlesByPhotoGrapher.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticlesByPhotoGrapher.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticleById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.article = action.payload;
      })
      .addCase(getArticleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticlesByCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlesByCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticlesByCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getArticleByTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticleByTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = action.payload;
      })
      .addCase(getArticleByTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getArticlesByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArticlesByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.article = action.payload;
      })
      .addCase(getArticlesByName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles.push(action.payload);
      })
      .addCase(createArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles.push(action.payload);
      })
      .addCase(updateArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addPhotoGrapherToArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPhotoGrapherToArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isAddPGSuccess = true;
        state.article = action.payload;
      })
      .addCase(addPhotoGrapherToArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editArticleData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editArticleData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles.push(action.payload);
      })
      .addCase(editArticleData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.articles = state.articles.filter(
          (article) => article._id !== action.payload.id
        );
      })
      .addCase(deleteArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateBannerArticle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBannerArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateBannerSuccess = true;
        state.articles.push(action.payload);
      })
      .addCase(updateBannerArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = articleSlice.actions;
export default articleSlice.reducer;
