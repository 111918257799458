import React, { useEffect, useRef } from 'react';
import DefaultLayout from '../components/DefaultLayout ';
import SunEditor, { buttonList } from 'suneditor-react';
import { uploadImage, uploadPic } from '../../utils/connect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {
  createArticle,
  getArticleById,
  updateArticle,
} from '../../features/article/articleSlice';
import { Button, Form, Input, Modal, Image, Select } from 'antd';
import { useState } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

import { getCategory, reset } from '../../features/category/categorySlice';
import Loading from '../../components/Loading';
import { getNameAuthors } from '../../features/author/authorSlice';

let controller = null;
const { Option } = Select;

const CreateArticle = ({ isEditing }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [media, setMedia] = useState(null);

  const {
    article,
    isLoading: LoadingArticle,
    isSuccess: success,
    isError: ErrorArticle,
    message: articleMessage,
  } = useSelector((state) => state.article);

  console.log('article:', article);

  let authorNameEdit = localStorage.getItem('content-author');
  let authorTitle = localStorage.getItem('content-title');
  let authorShort_des = localStorage.getItem('content-short_des');
  let authorCategory = localStorage.getItem('content-category');

  const myEditor = React.useRef(null);
  const getSunEditorInstance = (sunEditor) => {
    myEditor.current = sunEditor;
  };
  const titleref = React.useRef(null);
  const enTitleref = React.useRef(null);
  const authorref = React.useRef(null);
  const photoGrapherref = React.useRef(null);
  const descref = React.useRef(null);
  const category = React.useRef(null);
  const char = React.useRef(0);

  const dispatch = useDispatch();
  const [storystate, setStoryState] = React.useState(0);
  const src = React.useRef(null);
  const { aid } = useParams();
  const { user } = useSelector((state) => state.auth);

  const handleChange = (content) => {
    localStorage.setItem('content', content);
  };

  const { categories } = useSelector((state) => state.category);
  const { authors } = useSelector((state) => state.author);

  let categories2 = [];
  if (categories) {
    categories2 = categories.flat();
  }

  useEffect(() => {
    dispatch(getArticleById(aid));
  }, []);

  useEffect(() => {
    const t = document.querySelector("button[data-command='save']");
    t && (t.disabled = false);
    dispatch(getCategory());
    dispatch(getNameAuthors());

    form.setFieldsValue({
      author: authorNameEdit,
      title: authorTitle,
      short_des: authorShort_des,
      category: authorCategory,
    });

    return () => {
      dispatch(reset());
    };
  }, [authorNameEdit, authorCategory, authorShort_des, authorTitle]);

  const handelChange = (e) => {
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          2000,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            src.current = uri;
            setMedia(uri);
          },
          'base64'
        );
      } catch (err) {}
    }
  };

  const VerifyStory = () => {
    char.current = myEditor.current.getCharCount();
    return true;
  };
  const checkStoryWritten = () => {
    if (!VerifyStory()) {
      return;
    }

    setStoryState(1);
    setModalOpen(true);
  };

  const editStoryfunc = (articleData) => {
    const { html_content } = articleData;

    const payload = {};

    if (html_content) {
      payload.html_content = html_content;
    }

    if (Object.keys(payload).length === 0) {
      toast.error('You have not written anything yet', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    payload.aid = aid;
    payload.charcount = articleData.charcount;

    const callback = (res) => {
      if (res) {
        setTimeout(() => {
          window.location.replace('/articles');
        }, 100);
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    dispatch(updateArticle({ payload: payload, callback: callback }));
  };

  const submitStory = async () => {
    let profilePicUrl;
    if (media !== null) {
      profilePicUrl = await uploadPic(media);
    }
    if (media !== null && !profilePicUrl) {
      toast.error('Error Uploading Image');
    }

    let payload = {};
    if (isEditing) {
      payload = {
        html_content: localStorage.getItem('content'),

        charcount: char.current,
      };
    } else {
      payload = {
        title: titleref.current,
        enTitle: enTitleref.current,
        author: authorref.current,
        photoGrapher: photoGrapherref.current,
        short_des: descref.current,
        category: category.current,
        html_content: localStorage.getItem('content'),
        cover: src.current,

        charcount: char.current,
      };
    }

    if (!isEditing) {
      if (!payload.title || !payload.short_des || !payload.category) {
        return toast.error('Please fill all the fields', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (isEditing) {
      editStoryfunc(payload);
    } else {
      dispatch(
        createArticle({
          payload,
          callback: function (res, data) {
            if (res) {
            } else {
              toast.error('Article could not be posted', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          },
        })
      );
    }
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    uploadImage(files[0])
      .then((data) =>
        uploadHandler({
          result: [{ url: data, size: files[0].size, name: files[0].name }],
        })
      )
      .catch((err) => {
        uploadHandler('Image could not be uploaded');
      });
  };

  const handleImageUploadError = (errorMessage, result) => {};

  const goBack = () => {
    setStoryState(0);
    setTimeout(() => {
      const t = document.querySelector("button[data-command='save']");
      t && (t.disabled = false);
    }, 100);
  };

  const itemmmm = localStorage.getItem('content');

  console.log('itemmmm:', itemmmm);

  return (
    <DefaultLayout>
      {LoadingArticle ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {storystate === 0 ? (
            <>
              <SunEditor
                name='RockingBloggerEditor'
                height='100vh'
                width='100%'
                placeholder='เริ่มเขียนบทความ...'
                autoFocus={true}
                defaultValue={article.html_content}
                setDefaultStyle="font-family: 'Sarabun',  sans-serif; font-size: 16px;"
                getSunEditorInstance={getSunEditorInstance}
                onImageUploadBefore={handleImageUploadBefore}
                onChange={handleChange}
                onImageUploadError={handleImageUploadError}
                setOptions={{
                  buttonList: buttonList.complex,
                  charCounter: true,
                  charCounterType: 'char',
                  callBackSave: function (contents, isChanged) {
                    checkStoryWritten();
                  },
                  font: [
                    'Arial',
                    'Calibri',
                    'Sarabun',
                    'Tahoma',
                    'Kanit',
                    'Noto Sans Thai',
                    'Pattaty',
                  ],
                }}
              />
            </>
          ) : (
            <Modal
              title='ข้อมูลบทความ'
              visible={modalOpen}
              footer={false}
              onCancel={() => {
                setModalOpen(false);
                goBack();
              }}
            >
              {isEditing ? (
                <>
                  <div className='mt-3 text-center mb-5'>
                    <h5> ยืนยันการแก้ไขข้อมูล </h5>
                  </div>
                  <div className='d-flex justify-content-evenly '>
                    <Button className='btn-danger' onClick={goBack}>
                      กลับ
                    </Button>
                    <Button className='btn-primary' onClick={submitStory}>
                      โพส
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Form
                    name='basic'
                    initialValues={{
                      title: authorTitle,
                      short_desc: authorShort_des,
                      category: authorCategory,
                    }}
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    autoComplete='off'
                  >
                    <Form.Item
                      label='ชื่อผู้เขียน'
                      name='author'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your author!',
                        },
                      ]}
                    >
                      <Select
                        onChange={(value) => (authorref.current = value)}
                        placeholder='เลือกชื่อผู้เขียน'
                        allowClear
                      >
                        {authors.map((item) => {
                          return (
                            <Option key={item.name} value={item.name}>
                              {item.name}{' '}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='ชื่อบทความ-อังกฤษ'
                      name='enTitle'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your EN-title!',
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (enTitleref.current = e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label='ชื่อบทความ'
                      name='title'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your title!',
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (titleref.current = e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      label='คำอธิบายเบื้องต้น'
                      name='short_desc'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your short_desc!',
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => (descref.current = e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item label='ประเภท' name='category'>
                      <Select
                        style={{
                          width: 120,
                        }}
                        defaultValue={category}
                        onChange={(value) => (category.current = value)}
                      >
                        {categories2.map((e, index) => (
                          <Option de value={e.name} key={index}>
                            {e.name}{' '}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label='รูปภาพ' name='cover'>
                      <Input onChange={(e) => (src.current = e.target.value)} />
                    </Form.Item>

                    <input
                      name='imgUpload'
                      accept='image/*'
                      type='file'
                      onChange={handelChange}
                    />
                    <br />
                    <Image src={media} width={100} />
                    <br />
                  </Form>
                  <div className='d-flex justify-content-evenly '>
                    <Button className='btn-danger' onClick={goBack}>
                      กลับ
                    </Button>
                    <Button onClick={submitStory}>โพส</Button>
                  </div>
                </>
              )}
            </Modal>
          )}
        </>
      )}

      <ToastContainer />
    </DefaultLayout>
  );
};

export default CreateArticle;
