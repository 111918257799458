import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logout } from '../auth/authSlice';

// const API_URL = 'http://localhost:8000/api/author/';
//const API_URL = "//field-feel-server.proen.app.ruk-com.cloud/api/author/";

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000/api/author/';
} else {
  API_URL = '//api.field-feel.com/api/author/';
}

// Get user authors
const getAuthors = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  console.log(response);
  if (response.status === 401) {
    localStorage.removeItem('userInfo');
  }

  return response.data;
};

// Get Author By ID
const getAuthorDetail = async (authorId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'find/' + authorId, config);

  return response.data;
};

// Get Author By Name
const getAuthorByName = async (authorName) => {
  console.log(authorName);

  const response = await axios.get(API_URL + 'getByName', authorName);

  return response.data;
};

// Create new author
const createAuthor = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL, payload, config);
  console.log(res);

  if (res.status === 200) {
    callback(true);
    return;
  }
  callback(false);
  return res.data;
};

// Update author
const updateAuthor = async (authorData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
  };

  const response = await axios.put(
    API_URL + `update/${authorData.userId}`,
    authorData,
    config
  );

  return response.data;
};

// Delete author
const deleteAuthor = async ({ payload, callback }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + payload, config);

  console.log(res);

  if (res.status === 200) {
    callback(true);
    return true;
  }
  callback(false, null);
  return false;
};

// GET NAME
export const getNameAuthor = async (authorName) => {
  const response = await axios.get(API_URL + '/name/' + authorName);

  return response.data;
};

export const getNameAuthors = async () => {
  const response = await axios.get(API_URL + '/names');

  return response.data;
};

const authorService = {
  getAuthors,
  createAuthor,
  getNameAuthor,
  getAuthorDetail,
  updateAuthor,
  deleteAuthor,
  getAuthorByName,
  getNameAuthors,
};

export default authorService;
