import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';
import authorService from './authorService';

const initialState = {
  authors: [],
  author: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Create new author
export const createAuthor = createAsyncThunk(
  'author/create',
  async ({ payload, callback }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await authorService.createAuthor({ payload, callback }, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update author
export const updateAuthor = createAsyncThunk(
  'author/update',
  async (authorData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user;
      return await authorService.updateAuthor(authorData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get authors
export const getAuthors = createAsyncThunk(
  'authors/getAuthors',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authorService.getAuthors(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
      }

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getNameAuthors = createAsyncThunk(
  'authors/getNameAuthors',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authorService.getNameAuthors(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
      }

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get author detail
export const getAuthorById = createAsyncThunk(
  'authors/getAuthorById',
  async (authorId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authorService.getAuthorDetail(authorId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get author detail
export const getAuthorByName = createAsyncThunk(
  'authors/getAuthorByName',
  async (authorName, thunkAPI) => {
    try {
      return await authorService.getAuthorByName(authorName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete author
export const deleteAuthor = createAsyncThunk(
  'author/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authorService.deleteAuthor(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authorSlice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAuthor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAuthor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.author = action.payload;
      })
      .addCase(createAuthor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAuthor.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAuthor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.author = action.payload;
      })
      .addCase(updateAuthor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAuthors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authors.push(action.payload);
      })
      .addCase(getAuthors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNameAuthors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNameAuthors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authors = action.payload;
      })
      .addCase(getNameAuthors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAuthorById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthorById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.author = action.payload;
      })
      .addCase(getAuthorById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAuthorByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthorByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.author = action.payload;
      })
      .addCase(getAuthorByName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteAuthor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAuthor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authors = state.authors.filter(
          (author) => author._id !== action.payload.id
        );
      })
      .addCase(deleteAuthor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = authorSlice.actions;
export default authorSlice.reducer;
